import { IEnvironment } from "./ienvironment";
import sharedEnvironment from './base';

export const environment: IEnvironment = {
    ...sharedEnvironment,

    production: false,

    envName: '_____envName_____',
    appName: '_____appName_____',

    apiBaseUrl: '_____apimBaseUrl_____',
    apigeeApiKey: '_____apigeeApiKey_____',
  

    // Microservice URLs
    adjustmentsMicroserviceUrl: '_____adjustmentsMicroserviceUrl_____',
    alertMicroserviceUrl: '_____alertMicroserviceUrl_____',
    apaccitClientConfigMicroserviceURL: '_____apaccitClientConfigMicroserviceURL_____',
    apaccitConfigMicroserviceURL: '_____apaccitConfigMicroserviceURL_____',
    aragornNotificationAppId: '_____aragornNotificationAppId_____',
    aragornNotificationBaseUrl: '_____aragornNotificationBaseUrl_____',
    authenticationMicroserviceUrl: '_____authenticationMicroserviceUrl_____',
    authFacilitatorMicroserviceUrl: '_____authFacilitatorMicroserviceUrl_____',
    citEngagementSetupMicroserviceURL: '_____treMicroserviceUrl_____',
    clientContactsMicroserviceUrl: '_____clientContactsMicroserviceUrl_____',
    clientMasterDataMicroserviceUrl: '_____clientMasterDataMicroserviceUrl_____',
    clientMicroserviceUrl: '_____clientMicroserviceUrl_____',
    deliverableMicroserviceUrl: '_____deliverableMicroserviceUrl_____',
    documentMicroserviceUrl: '_____documentMicroserviceUrl_____',
    edwDataMicroserviceUrl: '_____edwDataMicroserviceUrl_____',
    engagementsMicroserviceUrl: '_____engagementsMicroserviceUrl_____',
    enhancedInformationRequestMicroserviceUrl: '_____enhancedInformationRequestMicroserviceUrl_____',
    entityMicroserviceUrl: '_____entityMicroserviceUrl_____',
    extensionsMicroserviceURL: '_____extensionsMicroserviceURL_____',
    favoriteserviceUrl: '_____favoriteserviceUrl_____',
    fedAuthServiceUrl: '_____fedAuthServiceUrl_____',
    fedauthzCompensatingTransactionServiceUrl: '_____fedauthzCompensatingTransactionServiceUrl_____',
    fileTransferMappingServiceUrl: '_____fileTransferIrMappingServiceUrl_____',
    fileTransferStatusServiceUrl: '_____fileTransferStatusServiceUrl_____',
    gatherTransportProvisioningUrl: '_____gatherTransportProvisioningUrl_____',
    gtpOdsdataServiceUrl: '_____gtpOdsdataServiceUrl_____',
    importMicroserviceUrl: '_____importMicroserviceUrl_____',
    infoRequestMicroserviceUrl: '_____infoRequestMicroserviceUrl_____',
    ingestion20MicroserviceUrl: '_____ingestion20MicroserviceUrl_____',
    ingestionMicroserviceUrl: '_____ingestionMicroserviceUrl_____',
    integrationCoreMicroserviceUrl: '_____integrationCoreMicroserviceUrl_____',
    integrationTaxFactMicroserviceURL: '_____taxFactDataMicroserviceURL_____',
    localizationMicroserviceUrl: '_____localizationMicroserviceUrl_____',
    masterDataMicroserviceUrl: '_____masterDataMicroserviceUrl_____',
    myDocsIntegrationServiceUrl: '_____myDocsIntegrationServiceUrl_____',
    notificationMicroserviceUrl: '_____notificationMicroserviceUrl_____',
    notificationTransmitterInAppUrl: '_____notificationTransmitterInAppUrl_____',
    notificationv4ServiceUrl: '_____notificationv4ServiceUrl_____',
    paymentsMicroserviceUrl: '_____paymentsMicroserviceUrl_____',
    portalcoreConfigurationserviceurl: '_____portalcoreConfigurationserviceurl_____',
    quickUploadServiceUrl: '_____quickUploadServiceUrl_____',
    reportingServiceUrl: '_____reportingServiceUrl_____',
    reviewNotesUrl: '_____reviewNotesUrl_____',
    rssFeedMicroserviceUrl: '_____rssFeedMicroserviceUrl_____',
    schedulerServiceUrl: '_____schedulerServiceUrl_____',
    serviceCatalogMicroserviceUrl: '_____serviceCatalogMicroserviceUrl_____',
    subscriptionServiceUrl: '_____subscriptionServiceUrl_____',
    SupportToolkitMicroserviceUrl: '_____supportToolkitMicroserviceUrl_____',
    taskMicroserviceUrl: '_____taskMicroserviceUrl_____',
    transformationMicroserviceUrl: '_____transformationMicroserviceUrl_____',
    twitterMicroserviceUrl: '_____twitterMicroserviceUrl_____',
    uscitEngagementSrv: '_____uscitEngagementSrv_____',
    uscittaxapiserviceurl: '_____uscittaxapiserviceurl_____',
    userMicroserviceUrl: '_____userMicroserviceUrl_____',
    userServiceWAFBaseUrl: '_____AuthService_____',
    virusScanMicroserviceUrl: '_____antivirusasyncMicroserviceUrl_____',
    widgetsMicroserviceUrl: '_____widgetsMicroserviceUrl_____',
    workspacesMicroserviceUrl: '_____workspacesMicroserviceUrl_____',
    gtpmyworknpUrl: '_____gtpmyworknpUrl_____',
    adobeAnalytics: {
        launchUrl: '_____adobeAnalyticsLaunchUrl_____'
    },
    appInsights: {
        correlationHeaderExcludedDomains: <any>'_____appInsightsCorrelationHeaderExcludedDomains_____',
        disableTelemetry: '_____appInsightsDisableTelemetry_____',
        instrumentationKey: '_____appInsightsInstrumentationKey_____',
        roleName: 'futureportal'
    },
    gtpWEnvIntegrationScript: '_____gtpWIntegrationScript_____',

    blobStorage: {
        blockSize: '_____blobStorageBlockSize_____',
        concurrency: '_____blobStorageCurrency_____',
        maxFileSize: '_____blobStorageMaxFileSize_____',
        maxSingleShotSize: '_____blobStorageMaxSingleShotSize_____'
    },
    externalUrls: {
        ogm: '_____externalUrlsOgm_____',
        ogmTask: '_____externalUrlsOgmTask_____',
        yFiles: [],
        gtpit: "_____externalUrlsGtpit_____"
    },
    gtpComponentClassId: {
        taxPeriods: '_____gtpComponentClassIdTaxPeriods_____'
    },
    serviceNow: {
        baseUrl: '_____serviceNowBaseUrl_____'
    },
    embeddedAppLinks:{
        baseBeehiveUrl: '_____embeddedAppLinksBaseBeehiveUrl_____'
    },
    smartFormsIntegration: {
        infoItemId: '_____smartFormsIntegrationInfoItemId_____',
        baseBeehiveUrl: '_____smartFormsIntegrationBaseBeehiveUrl_____'
    },

    irExternalUrl: {
        optimex: '_____optimexServiceURL_____'
    },

    pendoUI:{
        scriptUrl: '_____pendoUIScriptUrl_____'
    },

    cdnUrl: '_____cdnUrl_____',
    helpUri: '_____helpUri_____',
    logUncaughtErrors: '_____logUncaughtErrors_____',
    moduleFederationManifestUrl: '_____moduleFederationManifestUrl_____',
    myDocsProvisioningUrl: '_____provisioningUrl_____',
    notificationAppId: '_____notificationAppId_____',
    privacyApprovalDate: '_____privacyApprovalDate_____',
    privacyNoticeHttpsUrl: '_____privacyNoticeHttpsUrl_____',
    releasesAndNoticesUrl: '_____releasesAndNoticesUrl_____',
    taxNewsUpdateGlobalEditionUrl : '_____taxNewsUpdateGlobalEditionUrl_____',
    taxNewsUpdateUSEditionUrl : '_____taxNewsUpdateUSEditionUrl_____',
    splitauthorizationKey: '_____splitauthorizationKey_____',
    termsOfUseHttpsUrl: '_____termsOfUseHttpsUrl_____',
    toastUncaughtErrors: '_____toastUncaughtErrors_____',
    translationSourceFileNameToAppend: '_____translationSourceFileNameToAppend_____',
    translationStorageUrl: '_____translationStorageUrl_____',
    transmitterInAppUrl: '_____transmitterInAppUrl_____',
    yFiles4HtmlLicense: '_____yFiles4HtmlLicense_____',
    spreadJSLicense: '_____spreadJSLicense_____',
    spreadJSDesignerLicense: '_____spreadJSDesignerLicense_____'
};
